<template>
    <div>
        <b-table
            ref="invoice-detail"
            v-if="items.length"
            title="Invoice Details"
            :items="items"
            :fields="fields"
            select-mode="multi"
            selectable
            @row-selected="toggleSelected"
        >
            <template v-slot:cell(checkbox)="{ rowSelected, index, item }">
                <div v-if="!item.is_credited && item.price.amount > 0" class="custom-control custom-checkbox">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`checkbox-${item.id}`"
                        :checked="rowSelected"
                        @change="selectInvoiceDetailRow(index, item)"
                    />
                    <label class="custom-control-label" :for="`checkbox-${item.id}`"/>
                </div>
            </template>

            <template v-slot:cell(is_credited)="row">
                <b-badge v-if="row.item.price.amount > 0" :variant="row.item.is_credited ? 'danger' : 'primary'">
                    {{ row.item.is_credited ? 'Credited' : 'Not Credited' }}
                </b-badge>
            </template>
        </b-table>

        <b-row>
            <b-col v-if="canCreditAndMarkNotUsed">
                <b-row align-v="end">
                    <b-col>
                        <b-form-group label="Comment">
                            <b-input v-model="comment" required />
                        </b-form-group>
                    </b-col>
                    <b-col cols="auto">
                        <b-button variant="primary" :disabled="isLoading" style="margin-bottom: 1rem" @click.prevent="markPartialCredit()">
                            Part credit invoice
                        </b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {invoices} from '@/api'
import {formatMoney, formatDate} from '../page-helpers'

export default {
    name: 'InvoiceDetails',
    props: {invoice: {type: Object}},
    data() {
        return {
            items: [],
            checkedInvoiceDetails: [],
            comment: '',
            isLoading: false,
        }
    },
    computed: {
        fields() {
            return [
                {key: 'checkbox', label: ''},
                {key: 'id', label: 'Reference ID', sortable: false},
                {key: 'product_name', label: 'Product', sortable: false},
                {key: 'voucher_code', label: 'Voucher', sortable: false},
                {key: 'total', label: 'Sold for', sortable: false, formatter: value => formatMoney(value)},
                {key: 'price', label: 'Price', sortable: false, formatter: value => formatMoney(value)},
                {key: 'commission', label: 'Commission', sortable: false, formatter: value => formatMoney(value)},
                {key: 'vat', label: 'VAT', sortable: false, formatter: value => formatMoney(value)},
                {key: 'is_credited', label: 'Credited', sortable: false},
                {key: 'created_at', label: 'Created At', sortable: false, formatter: value => formatDate(value)},
                {key: 'experienced_at', label: 'Experienced At', sortable: false, formatter: value => value ? formatDate(value) : value ?? 'N/A'},
            ]
        },
        checkId() {
            return Math.random().toString(4)
        },
        canCreditAndMarkNotUsed() {
            return this.checkedInvoiceDetails.length &&
                this.checkedInvoiceDetails.every(
                invoiceDetail =>
                    invoiceDetail &&
                    invoiceDetail.refunded_at === null &&
                    invoiceDetail.status === 'redeemed_invoiced'
            )
        },
    },
    mounted() {
        this.loadContent()
    },
    methods: {
        async loadContent() {
            const details = await invoices.details(this.invoice.id)
            this.items = details.data()
            this.$emit('loaded')
        },
        async markPartialCredit() {
            this.isLoading = true
            try {
                await invoices.partialCredit({
                    'invoice_details_id': this.checkedInvoiceDetails.map(od => od.id),
                    'comment': this.comment
                })
                await this.loadContent()
                this.isLoading = false
                this.comment = null
                this.checkedInvoiceDetails = []
                const table = this.$refs['invoice-detail']
                table.refresh()
                this.$bvToast.toast(`Invoice part credited.`, {
                    title: 'Invoice Credited',
                    variant: 'primary',
                })
            } catch (error) {
                this.$bvToast.toast(error.message, {
                    title: 'Something went wrong...',
                    variant: 'danger',
                })
            }
        },
        toggleSelected(rows) {
            this.checkedInvoiceDetails = rows.filter((row) => !row.is_credited)
        },
        selectInvoiceDetailRow(index, item) {
            const table = this.$refs['invoice-detail']

            if (table.isRowSelected(index)) {
                const itemIndex = this.checkedInvoiceDetails.findIndex(id => id === item.id)
                this.checkedInvoiceDetails.splice(itemIndex, 1)
                table.unselectRow(index)
            } else {
                this.checkedInvoiceDetails = [...new Set([...this.checkedInvoiceDetails, item.id])]
                table.selectRow(index)
            }
        },
    },
}
</script>
