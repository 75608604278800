<template>
  <div id="content" class="app-content">
    <!-- page header -->
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="page-header">Invoices <small>all listings.</small></h1>

      <InvoicesDownloadForm/>
    </div>

    <div class="mb-4 d-flex justify-content-between">
      <template v-for="(statisticsBlock, index) in statisticsBlocks">
        <div class="border border-secondary rounded-lg text-center bg-white p-3 w-25" v-if="statistics[statisticsBlock.key]" :key="index">
          <h5>{{ statisticsBlock.name }}</h5>
          <h3 class="mb-0">{{ formatMoney(statistics[statisticsBlock.key])}} </h3>
        </div>
      </template>
    </div>

    <display-results
      ref="orderSearch"
      v-slot:default="{ items }"
      :index-name="invoicesIndex"
      searchable-facet-default="supplier"
      :facetList="[
        { text: 'Legal name', value: 'supplier' },
        { text: 'Invoice ID', value: 'id' },
        { text: 'Voucher number', value: 'voucher_number'}
      ]"
    >
      <mega-table ref="table" :items="items" :fields="fields" show-details>
        <template v-slot:cell(pdf)="{ item }">
          <a href="#" @click.prevent="downloadInvoice(item.id)"> <i class="fa fa-download" /> Download </a>
        </template>
        <template v-slot:cell(paid_at)="{ value, item }">
          <span v-if="value">{{ value }}</span>
          <b-button v-else size="sm" variant="default" @click="markAsPaid(item)">Mark as Paid</b-button>
        </template>
        <template v-slot:row-details="{ item, loaded }">
          <invoice-details :invoice="item" @loaded="loaded" />
        </template>
      </mega-table>
    </display-results>
  </div>
</template>

<script>
import { invoices } from '@/api'
import MegaTable from '../../components/MegaTable'
import { formatMoney, formatDate } from '../page-helpers'
import InvoiceDetails from './InvoiceDetails.vue'
import DisplayResults from "@/components/DisplayResults.vue";
import InvoicesDownloadForm from "@/pages/Invoices/InvoicesDownloadForm.vue";

export default {
  name: 'InvoicePage',
  components: {DisplayResults, MegaTable, InvoiceDetails, InvoicesDownloadForm},
  data() {
    return {
      statisticsBlocks: [
        {name: 'Invoices due', key: 'invoices_due'},
        {name: 'Upcoming invoices', key: 'upcoming_invoices'},
        {name: 'Not yet invoiced', key: 'not_yet_invoiced'},
      ],
      statistics: {},
      invoicesIndex: process.env.VUE_APP_ALGOLIA_INVOICES_INDEX,
    }
  },
  mounted() {
    invoices.statistics().then((statistics) => this.statistics = statistics)
  },
  computed: {
    invoices() {
      return invoices
    },
    fields() {
      return [
        { key: 'id', label: 'ID', sortable: false },
        {
          key: 'supplier',
          label: 'Legal name',
          sortable: false,
          formatter: value => value.invoice_legal_name,
        },
        { key: 'expires_at', formatter: value => value ? formatDate(value) : value ?? 'N/A' },
        { key: 'transaction_sum', formatter: value => formatMoney(value) },
        { key: 'sum', formatter: value => formatMoney(value) },
        { key: 'created_at', formatter: value => formatDate(value) },
        { key: 'paid_at', formatter: value => (value ? formatDate(value) : null) },
        { key: 'pdf', label: 'Download' },
      ]
    },
  },
  methods: {
    formatMoney,
    async markAsPaid(item) {
      await this.invoices.markAsPaid(item.id)
      this.$bvToast.toast(`Invoice #${item.id} was successfully marked as paid.`, {
        title: 'Invoice paid',
        variant: 'primary',
      })
      this.$refs.table.refresh()
    },
    async downloadInvoice(id) {
      const res = await invoices.download(id)
      window.open(res.url, '_blank').focus()
    },
  },
}
</script>
