<template>
  <validation-observer v-slot="{ invalid }" ref="invoicesDownloadForm">
    <form class="d-flex">
      <validation-provider name="Invoice ID from" v-slot="context" rules="required|integer" class="mr-2">
        <b-form-group>
          <b-form-input type="number"
            v-model="invoice_id_from"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="Invoice ID from"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>


      <validation-provider name="Invoice ID to" v-slot="context" :rules="'required|integer|min_value:' + invoice_id_from" class="mr-2">
        <b-form-group>
          <b-form-input
            type="number"
            v-model="invoice_id_to"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="Invoice ID to"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <div>
        <b-dropdown right variant="primary" :disabled="invalid || isLoading">
          <template #button-content>
            Download
            <b-spinner v-show="isLoading" class="ml-2" small label="Loading" />
          </template>

          <b-dropdown-item-button @click="submitFormData('zip')">
            Download Zipped PDFs
          </b-dropdown-item-button>

          <b-dropdown-divider/>

          <b-dropdown-item-button @click="submitFormData('xlsx')">
            Download transfer details
          </b-dropdown-item-button>
        </b-dropdown>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import { invoices } from '@/api'
import { getValidationState } from '../page-helpers'
import { downloadFile } from "@/helpers/files";

export default {
  data() {
    return {
      invoice_id_from: '',
      invoice_id_to: '',
      isLoading: false
    }
  },

  methods: {
    getValidationState,

    async submitFormData(format) {
      this.isLoading = true;

      const blob = await invoices.downloadZipOrXlsx({
        format: format,
        invoice_id_from: this.invoice_id_from,
        invoice_id_to: this.invoice_id_to
      })

      downloadFile(blob, `invoices-from-${this.invoice_id_from}-to-${this.invoice_id_to}.${format}`);

      this.invoice_id_from = '';
      this.invoice_id_to = '';
      this.isLoading = false;

      this.$nextTick(() => this.$refs.invoicesDownloadForm.reset());
    },
  },
}
</script>
