<template>
  <b-card :title="title">
    <b-table
      ref="table"
      :fields="mapFields"
      :items="mapItems"
      show-empty
      stacked="sm"
      bordered
      hover
      select-mode="multi"
      :selectable="selectable"
      @row-clicked="row => handleShowDetails(row)"
    >
      <template v-slot:head(checkbox)>
        <div class="custom-control custom-checkbox">
          <input
            v-model="headChecked"
            type="checkbox"
            class="custom-control-input"
            @change="selectAllCheckedRows()"
            :id="headCheckboxId"
          />
          <label class="custom-control-label" :for="headCheckboxId" />
        </div>
      </template>

      <template v-slot:cell(checkbox)="{ rowSelected, index, item }">
        <div class="custom-control custom-checkbox">
          <input
            type="checkbox"
            class="custom-control-input"
            :id="`checkbox-${item.id}`"
            :checked="rowSelected"
            @input="selectCheckedRow(index, item)"
          />
          <label class="custom-control-label" :for="`checkbox-${item.id}`" />
        </div>
      </template>

      <template v-for="slot in customSlots" v-slot:[`${slot}`]="row">
        <slot :name="slot" v-bind="row" />
      </template>

      <template v-if="showDetails" v-slot:row-details="row">
        <b-overlay :show="rowLoading">
          <slot name="row-details" v-bind="row" :loaded="onRowLoaded" />
        </b-overlay>
      </template>
    </b-table>

    <!-- pagination -->
    <b-pagination
      v-if="meta.total > meta.per_page"
      v-model="meta.current_page"
      :total-rows="meta.total"
      :per-page="meta.per_page"
      @change="paginationChange"
    />
  </b-card>
</template>

<script>
export default {
  name: 'MegaTable',
  props: {
    fields: { type: Array, default: () => [] },
    items: { type: Array },
    api: { type: Object },
    paginationRoute: { type: Object },
    showDetails: { type: Boolean },
    title: { type: String },
    selectable: { type: Boolean },
  },
  mounted() {
    this.loadContent()
  },
  data() {
    return {
      headChecked: false,
      rowItems: [],
      checkedItems: [],
      currentRow: 0,
      rowLoading: false,
      meta: {
        current_page: 1,
        from: 0,
        last_page: 1,
        path: '',
        per_page: 0,
        to: 0,
        total: 0,
      },
    }
  },
  watch: {
    items: function (items) {
      this.rowItems = items
    }
  },
  computed: {
    mapFields() {
      return (this.selectable ? [{ key: 'checkbox' }] : []).concat(this.fields)
    },
    mapItems() {
      return this.showDetails
        ? this.rowItems.map(item => ({ ...item, _showDetails: this.currentRow === item.id }))
        : this.rowItems.map(item => ({ ...item, _showDetails: false }))
    },
    customSlots() {
      return Object.keys(this.$scopedSlots).filter(slot => !['row-details'].includes(slot))
    },
    headCheckboxId() {
      return (
        '_' +
        Math.random()
          .toString(36)
          .substr(2, 9)
      )
    },
  },
  methods: {
    async loadContent() {
      if (this.items) {
        this.rowItems = this.items
        return
      }

      const page = this.$route.query.page ?? 1
      const response = await this.api.go(page)
      this.rowItems = response.data()
      this.meta = response.meta
    },
    handleShowDetails(item) {
      this.rowLoading = true
      if (this.showDetails) {
        this.currentRow = item.id === this.currentRow ? 0 : item.id
      } else {
        this.currentRow = 0
      }
    },
    paginationChange(page) {
      this.$router.push(page === 1 ? this.paginationRoute : { ...this.paginationRoute, query: { page } })

      this.loadContent()
    },
    selectAllCheckedRows() {
      const table = this.$refs.table
      if (this.rowItems.length === this.checkedItems.length) {
        this.checkedItems = []
        table.clearSelected()
        this.headChecked = false
      } else {
        this.checkedItems = this.rowItems.map(item => item.id)
        table.selectAllRows()
        this.headChecked = true
      }
    },
    selectCheckedRow(index, item) {
      const table = this.$refs.table

      if (table.isRowSelected(index)) {
        const itemIndex = this.checkedItems.findIndex(id => id === item.id)
        this.checkedItems.splice(itemIndex, 1)
        table.unselectRow(index)
        this.headChecked = false
      } else {
        this.checkedItems = [...new Set([...this.checkedItems, item.id])]
        table.selectRow(index)
        this.headChecked = this.rowItems.length === this.checkedItems.length
      }
    },
    refresh() {
      this.loadContent()
    },
    onRowLoaded() {
      this.rowLoading = false
    },
  },
}
</script>
